<template>
  <div>
    <div class="card">
      <div v-if="transactions.length" class="my-transactions">
        <h5>Transactions</h5>
        <DataTable
          :value="transactions"
          :paginator="true"
          :rows="3"
          dataKey="id"
          v-model:transactions="transactions"
          responsiveLayout="scroll"
        >
          <Column
            header="id"
            field="id"
            style="word-break: break-all"
            :sortable="true"
          >
          </Column>

          <Column
            header="addressFrom"
            field="addressFrom"
            style="word-break: break-all"
            :sortable="true"
          >
          </Column>
          <Column
            header="addressTo"
            field="addressTo"
            style="word-break: break-all"
            :sortable="true"
          >
          </Column>
          <Column
            header="createdAt"
            field="createdAt"
            style="word-break: break-all"
            :sortable="true"
          >
          </Column>
          <Column
          header="hash"
          field="hash"
          style="word-break: break-all">
          </Column>

          <Column
            header="methodName"
            field="methodName"
            style="word-break: break-all"
          >
          </Column>
        </DataTable>
      </div>
      <div v-else class="no-transactions">
        <p>Not any transactions yet</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Transactions',
  head() {
    return {
      title: 'Transactions',
    }
  },
  data() {
    return {
      transactions: {},
      item: {},
      // userId: this.$auth.$vm.state.data.id, // В целом не нужно
      openDialog: false, // открывает диалог open
      sendDialog: false, // открывает диалог send
      saleDialog: false, // открывает диалог sale
    }
  },
  async mounted() {
    this.getTransactions()
  },
  methods: {
    async getTransactions() {
      const resp = await this.axios.get('api/transactions')

      this.transactions = resp.data
    },
  },
}
</script>

<style scoped>
[data-help] {
  position: relative;
}
[data-help]::after {
  content: attr(data-help);
  position: absolute;
  left: 7px;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}
[data-help]:hover::after {
  opacity: 1;
  top: -1.5em;
}
</style>
